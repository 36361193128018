.#{$prefix}_reviews {
  padding: 125px 0 115px;
  background: white;
    &-slider {
      position: relative;
      width: 100%;
      padding-top: 15px;
        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          display: block;
          width: 210px;
          height: 184px;
          background: $sprite 0 0;
          z-index: 0;
        }
        &__item {
          padding-left: 60px;
          padding-right: 60px;
          @include align_c;
        }
    }
    &-text {
      margin: 0;
      font-size: 32px;
      font-weight: 300;
      line-height: 52px;
    }
    &-author {
      margin-top: 40px;
      margin-bottom: 0;
      font-size: 20px;
      font-weight: 400;
      line-height: 26px;
    }
    &-company {
      margin-top: 15px;
      margin-bottom: 0;
      font-size: 20px;
      font-weight: 300;
      line-height: 26px;
    }
    @media all and (max-width: 769px) {
      padding-top: 65px;
        &-slider {
          padding-top: 0;
            &::before {
              top: auto;
              left: 50%;
              bottom: -45px;
              margin-left: -105px;
            }
        }
        &-text {
          font-size: 25px;
          line-height: 40px;
        }
        &-author {
          margin-top: 35px;
        }
    }
    @media all and (max-width: 599px) {
      padding-bottom: 70px;
        &-slider {
          padding-bottom: 35px;
            &::before {
              bottom: 20px;
              width: 143px;
              height: 125px;
              margin-left: -62.5px;
              background-position: -221px 0;
            }
            &__item {
              padding: 0;
            }
        }
        &-text {
          font-size: 18px;
          line-height: 28px;
        }
        &-author {
          max-width: 230px;
          @include auto;
          font-size: 18px;
          line-height: 25px;
        }
        &-company {
          font-size: 17px;
          line-height: 26px;
        }
    }
}
/* all variables and mixins are in this file */
@import "variables";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}

/*
	Set font selection according to the website color scheme
*/

*::selection {
  background: $theme;
  color: white;
}
*::-moz-selection {
  background: $theme;
  color: white;
}

._fix {
  &::before,
  &::after {
    @include fix;
  }
}

._hide,
._none,
[hidden] {
  display: none!important;
  visibility: hidden!important;
}

._noselect {
  @include noselect;
}

._theme {
  color: $theme;
}
._blue {
  color: $lightBlue;
}

._align-l {
  @include align_l;
}
._align-c {
  @include align_c;
}
._align-r {
  @include align_r;
}
._align-j {
  @include align_j;
}

._bold {
  font-weight: 700;
}
._upp {
  @include upp;
}

._flex {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.i {
  @include iblock(inherit);
  background: $sprite;
    &._shevron-left {
      width: 17px;
      height: 27px;
      background-position: -373px 0;
    }
    &._shevron-right {
      width: 17px;
      height: 27px;
      background-position: -401px 0;
    }
    &._router {
      width: 39px;
      height: 32px;
      background-position: -427px 0;
    }
    &._tel {
      width: 32px;
      height: 32px;
      background-position: -548px 0;
    }
    &._mobile {
      width: 23px;
      height: 38px;
      background-position: -651px 0;
    }
    &._pc {
      width: 29px;
      height: 26px;
      background-position: -731px  0;
    }
    &._headset {
      width: 100px;
      height: 100px;
      background-position: -373px -63px;
    }
    &._phone {
      width: 100px;
      height: 100px;
      background-position: -476px -63px;
    }
    &._list {
      width: 100px;
      height: 100px;
      background-position: -580px -63px;
    }

    @media all and (max-width: 769px) {
      &._router {
        width: 62px;
        height: 47px;
        background-position: -476px 0;
      }
      &._tel {
        width: 51px;
        height: 51px;
        background-position: -590px 0;
      }
      &._mobile {
        width: 37px;
        height: 60px;
        background-position: -684px 0;
      }
      &._pc {
        width: 46px;
        height: 41px;
        background-position: -770px  0;
      }
    }
}

span {
  &.#{$prefix}_value {
    @include inherit;
  }
}
.#{$prefix}_table {
  @include table(100%);
  &__cell {
    @include td(initial);
    &._top {
      vertical-align: top;
    }
    &._middle {
      vertical-align: middle;
    }
    &._bottom {
      vertical-align: bottom;
    }
  }
}
//controls
.#{$prefix}_select {
  select {
    display: block;
    width: 100%;
    height: 100%;
    outline: 0;
  }
  .jq-selectbox {
    @include iblock(top);
    font-size: $_14px;
    font-weight: 400;
    cursor: pointer;
    @include noselect;
    &.opened {
      .jq-selectbox__trigger-arrow {
        transform: rotate(-180deg);
      }
    }
    &.changed {
      .jq-selectbox__dropdown {
        ul {
          li.sel {
            color: $theme;
          }
        }
      }
    }

    &__select {
      padding-right: 15px;
    }
  }
  .jq-selectbox__select-text {
    display: block;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .jq-selectbox__trigger {
    position: absolute;
    top: 0;
    right: 0;
    width: 10px;
    height: 100%;
    background: transparent;
  }
  .jq-selectbox__trigger-arrow {
    position: absolute;
    top: 50%;
    right: 0;
    width: 8px;
    height: 6px;
    margin-top: -4px;
    background: $sprite -12px -27px;
    @include smooth(.15s);
  }
  .jq-selectbox__dropdown {
    width: 100%;
    margin: 0;
    padding: 0;
    ul {
      width: 100%;
      margin: 0;
      max-height: 350px;
      overflow: auto;
      li {
        padding-left: 0;
        &:not(:last-of-type) {
          margin-bottom: 0;
        }
        &::before {
          display: none;
        }
      }
    }
  }
  .jq-selectbox__search {
    display: none;
    input {
      display: block;
      width: 100%;
      height: 35px;
      padding: 0 10px;
      border: 1px solid;
    }
  }
  .jq-selectbox__not-found {
    padding: 5px 10px;
  }
  &._type_1 {
    .jq-selectbox {
      width: 100%;
    }
    .jq-selectbox__select {
      height: 50px;
      background: white;
      border: 1px solid $theme;
      border-radius: 4px;
      @include smooth(.15s);
    }
    .jq-selectbox__select-text {
      padding: 0 60px 0 15px;
      line-height: 47px;
    }
    .jq-selectbox__trigger {
      width: 50px;
    }
    .jq-selectbox__trigger-arrow {
      top: 23px;
      right: 21px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 4px 4px 0 4px;
      border-color: $theme transparent transparent transparent;
    }
    .jq-selectbox__dropdown {
      top: 52px;
      min-width: 100%;
      ul {
        padding: 1px;
        background: white;
        border-radius: 4px;
        border: 1px solid $theme;
        li {
          padding: 10px 15px;
          &:hover {
            color: white!important;
            background: $theme;
          }
        }
      }
    }
  }
  &._type_2 {
    .jq-selectbox {
      width: 100%;
      color: $color;
      font-size: $_15px;
    }
    .jq-selectbox__select {
      height: 43px;
      background: white;
      @include smooth(.15s);
    }
    .jq-selectbox__select-text {
      padding: 0 53px 0 22px;
      line-height: 42px;
    }
    .jq-selectbox__trigger {
      width: 43px;
      height: 43px;
      background: $theme;
    }
    .jq-selectbox__trigger-arrow {
      top: 22px;
      right: 15px;
      width: 15px;
      height: 9px;
      border: 0;
      background: $sprite -124px -27px;
    }
    .jq-selectbox__dropdown {
      top: 45px;
      min-width: 100%;
      border: 1px solid $theme;
      ul {
        padding: 1px;
        background: white;
        li {
          padding: 10px 15px;
          color: inherit;
          &.selected,
          &:hover {
            color: white!important;
            background: $theme;
          }
        }
      }
    }
  }
  &._no-border {
    @include iblock(top);
    .jq-selectbox {
      &__select-text {
        color: $theme;
        font-size: $_18px;
        @include upp;
        border-bottom: 1px solid $theme;
      }
      &__dropdown {
        margin-top: 2px;
        min-width: 200px;
        background: white;
        border: 1px solid $theme;
        ul {
          padding: 1px;
          font-size: 1rem;
          color: $color;
          @include upp;
          li {
            padding: 5px;
            &.selected,
            &:hover {
              color: white!important;
              background: $theme;
            }
          }
        }
      }
    }
  }
}
.#{$prefix}_input {
  input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]) {
    @include input(46px, 0 20px)
  }
  textarea {

  }
  input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]),
  textarea {
    &:invalid {
      border-color: $red;
    }
  }
  &._error {
    input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]),
    textarea {
      border-color: $red;
    }
  }
}

//container
.container {
  max-width: 1262px;
  padding-left: 20px;
  padding-right: 20px;
}

//button
.btn {
  @include align_c;
  &._huge {
    padding: 13px 60px;
    font-weight: 400;
  }
  &._blue {
    background: $blue;
    box-shadow: inset 0 0 5px 0 rgba(246,149,138,.36), 0 7px 0 0 $darkBlue;
      &:hover {
        background: lighten($blue, 5%);
        box-shadow: inset 0 0 5px 0 rgba(246,149,138,.36), 0 7px 0 0 lighten($darkBlue, 5%);
      }
      &:active {
        box-shadow: inset 0 0 5px 0 rgba(246,149,138,.36), 0 2px 0 0 darken($darkBlue, 5%);
      }
  }
}

//heading
.#{$prefix}_heading {
  margin-top: 0;
  margin-bottom: 55px;
  color: $color;
  font-size: 35px;
  font-weight: 400;
  @include align_c;
    @media all and (max-width: 481px) {
      font-size: 32px;
    }
}
//sub-text
.#{$prefix}_sub-text {
  margin: 0;
  font-size: 18px;
  font-weight: 400;
  @include align_c;
  line-height: 27px;
}

//logotype
.#{$prefix}_logotype {
  @include iblock(top);
    &-link {
      @include link(auto);
    }
    img {
      @include iblock(top);
      width: auto;
      max-width: 100%;
      height: auto;
      bottom: 0;
    }
}
//copyright
.#{$prefix}_copyright {
  @include iblock(top);
  margin: 0;
  color: $color;
  font-size: 15px;
  font-weight: 400;
    p {
      margin: 0;
      @include inherit;
      line-height: normal;
    }
}
//sitemap
.#{$prefix}_sitemap {
  @include iblock(top);
  color: $theme;
  font-size: 15px;
    a {
      @include inherit;
      text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
    }
}
.#{$prefix}_capability {
  padding: 75px 0 80px;
  background: $lightBlue;
    &-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    &-item {
      width: 360px;
      @include align_c;
        &__icon {
          margin-bottom: 35px;
        }
        &__heading {
          margin-bottom: 10px;
          font-size: 25px;
          font-weight: 400;
            a {
              @include inherit;
              text-decoration: none;
                &:hover {
                  text-decoration: underline;
                }
            }
        }
        &__text {
          margin: 0;
          color: $grey;
          font-size: 15px;
          line-height: 22px;
        }
    }
    &-more {
      margin-top: 55px;
      @include align_c;
        &__link {
          position: relative;
          @include iblock(top);
          padding-right: 20px;
          color: $link;
          font-size: 15px;
          font-weight: 700;
          text-decoration: underline;
            &:hover {
              text-decoration: none;
            }
            &::after {
              content: "";
              position: absolute;
              top: 2px;
              right: 0;
              display: block;
              width: 10px;
              height: 13px;
              background: $sprite -684px -63px;
            }
        }
    }
    @media all and (max-width: 769px) {
      .#{$prefix}_heading {
        margin-bottom: 95px;
      }
      &-item {
        width: 100%;
        max-width: 640px;
        @include auto;
          &:not(:last-of-type) {
            margin-bottom: 75px;
          }
          &__text {
            font-size: 18px;
            line-height: 32px;
          }
      }
    }
}
.#{$prefix}_devices {
  position: relative;
  padding: 75px 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
    &-image {
      position: absolute;
      top: 0;
      left: 0;
      @include full;
        &._desktop {
          display: block;
        }
        &._tablet,
        &._mobile {
          display: none;
          background-color: #f4f5dc;
          background-size: 100%;
          background-position: center bottom;
        }
        @media all and (max-width: 769px) {
          &._desktop {
            display: none;
          }
          &._tablet {
            display: block;
          }
        }
        @media all and (max-width: 481px) {
          &._tablet {
            display: none;
          }
          &._mobile {
            display: block;
          }
        }
    }
    .container {
      position: relative;
      z-index: 1;
    }
    .#{$prefix}_heading {
      margin-bottom: 25px;
      @include align_l;
    }
    &-prompt {
      margin-top: 0;
      margin-bottom: 40px;
      font-size: 19px;
      font-weight: 300;
    }
    &-item {
      @include table(auto);
      max-width: 850px;
        &:not(:last-of-type) {
          margin-bottom: 25px;
        }
        &__icon,
        &__details {
          @include td(top);
        }
        &__icon {
          width: 57px;
          @include align_c;
        }
        &__heading {
          margin-bottom: 10px;
          font-size: 18px;
          font-weight: 400;
        }
        p {
          margin: 0;
          color: $grey;
          font-size: 15px;
          font-weight: 400;
          line-height: 24px;
        }
    }
    @media all and (max-width: 769px) {
      .#{$prefix}_heading {
        max-width: 520px;
      }
      &-prompt {
        max-width: 620px;
      }
      &-item {
        display: block;
          &:not(:last-of-type) {
            margin-bottom: 50px;
          }
          &__icon,
          &__details {
            display: block;
            width: 100%;
          }
          &__icon {
            margin-bottom: 20px;
            @include align_l;
          }
          &__heading {
            margin-bottom: 0;
            font-size: 22px;
            line-height: 28px;
          }
          p {
            font-size: 18px;
            line-height: 28px;
          }
      }
    }
    @media all and (max-width: 599px) {
      .#{$prefix}_heading {
        font-size: 32px;
        line-height: 45px;
      }
    }
}
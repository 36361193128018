.#{$prefix}_features {
  padding: 90px 0 100px;
  background: $lightBlue;
    &-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    &-item {
      width: 340px;
      @media all and (max-width: 769px) {
        width: 100%;
          &:not(:last-of-type) {
            margin-bottom: 65px;
          }
          @media all and (max-width: 482px) {
            &:not(:last-of-type) {
              margin-bottom: 50px;
            }
          }
      }
        &__heading {
          margin-top: 0;
          margin-bottom: 30px;
          font-size: 28px;
          font-weight: 400;
          @media all and (max-width: 769px) {
            margin-bottom: 20px;
          }
        }
        &__text {
          margin: 0;
          color: $grey;
          font-size: 15px;
          line-height: 22px;
        }
    }
    @media all and (max-width: 769px) {
      padding-top: 70px;
      padding-bottom: 70px;
    }
}
.#{$prefix}_application {
  padding: 75px 0 80px;
  background: $lightBlue;
    .#{$prefix}_heading {
      margin-bottom: 10px;
    }
    &-form {
      margin-top: 45px;
        &__row {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
            &:not(:last-of-type) {
              margin-bottom: 40px;
              @media all and (max-width: 769px) {
                margin-bottom: 27px;
              }
              @media all and (max-width: 481px) {
                margin-bottom: 25px;
              }
            }
        }
    }
    .#{$prefix}_input {
      width: 252px;
        &:not(:last-of-type) {
          margin-right: 11px;
        }
    }
    @media all and (max-width: 769px) {
      .#{$prefix}_input {
        width: 100%;
        max-width: 570px;
          &:not(:last-of-type) {
            margin: 0 0 18px;
          }
      }
    }
    @media all and (max-width: 481px) {
      .#{$prefix}_input {
          &:not(:last-of-type) {
            margin: 0 0 22px;
          }
      }
    }
}
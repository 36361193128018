.#{$prefix}_intro {
  position: relative;
  width: 100%;
  height: 740px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
   &-image {
      position: absolute;
      top: 0;
      left: 0;
      @include full;
        &._desktop {
          display: block;
        }
        &._tablet,
        &._mobile {
          display: none;
          background-position: center bottom;
        }
        &._tablet {
         background-color: #a69e91;
        }
        &._mobile {
          background-color: #e7ebee;
          background-size: 100%;
          opacity: .5;
        }
    }
    @media all and (max-width: 1367px) {
      height: 565px;
    }
    @media all and (max-width: 769px) {
      height: 635px;
        &-image {
          &._desktop {
            display: none;
          }
          &._tablet {
            display: block;
          }
        }
    }
    @media all and (max-width: 481px) {
      height: auto;
      padding-top: 40px;
      padding-bottom: 90px;
      &-image {
        &._tablet {
          display: none;
        }
        &._mobile {
          display: block;
        }
      }
    }

    .container {
      position: relative;
      height: 100%;
      z-index: 1;
    }

    &-heading {
      margin-top: 0;
      margin-bottom: 37px;
      font-size: 50px;
      font-weight: 700;
      @include align_l;
    }
    &-text {
      max-width: 650px;
      margin: 0;
      font-size: 15px;
      line-height: 22px;
    }
    &-features {
      margin-top: 25px;
    }
    &-list {
      margin: 18px 0 0;
      padding: 0;
      font-size: 15px;
      list-style: none;
        &__item {
          position: relative;
          padding-left: 17px;
            &::before {
              content: '';
              position: absolute;
              top: 11px;
              left: 0;
              display: block;
              width: 10px;
              height: 1px;
              background: $color;
            }
            &:not(:last-of-type) {
              margin-bottom: 18px;
            }
        }
    }
    @media all and (max-width: 481px) {
      &-heading {
        margin-bottom: 40px;
        font-size: 40px;
        font-weight: 700;
        line-height: 47px;
      }
      &-text {
        font-size: 18px;
        line-height: 32px;
      }
      &-list {
        &__item {
          font-size: 18px;
            &::before {
              top: 13px;
            }
        }
      }
    }
    &-controls {
      margin-top: 35px;
      font-size: 0;
        .btn {
          width: 255px;
            &:not(:last-of-type) {
              margin-right: 11px;
            }
            @media all and (max-width: 769px) {
              min-width: initial;
            }
            @media all and (max-width: 599px) {
              width: 215px;
              padding-left: 20px;
              padding-right: 20px;
            }
            @media all and (max-width: 481px) {
              width: 100%;
              &:not(:last-of-type) {
                margin: 0 0 30px;
              }
            }
        }
    }
}
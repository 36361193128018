@import "../../bower_components/slick-carousel/slick/slick.css";

@import "modules/_variables";
@import "modules/_common";
@import "modules/_intro";
@import "modules/_features";
@import "modules/_reviews";
@import "modules/_arrows";
@import "modules/_dots";
@import "modules/_devices";
@import "modules/_capability";
@import "modules/_application";
@import "modules/_description";
@import "modules/_footer";
@import "modules/_tags";
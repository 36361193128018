.#{$prefix}_description {
  position: relative;
  padding: 95px 0 75px;
  color: white;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
    .container {
      position: relative;
      z-index: 1;
    }
    &-image {
      position: absolute;
      top: 0;
      left: 0;
      @include full;
        &._desktop {
          display: block;
        }
        &._tablet,
        &._mobile {
          display: none;
          background-color: #486583;
          background-size: contain;
          background-position: center bottom;
        }
        @media all and (max-width: 769px) {
          &._desktop {
            display: none;
          }
          &._tablet {
            display: block;
          }
        }
        @media all and (max-width: 481px) {
          &._tablet {
            display: none;
          }
          &._mobile {
            display: block;
          }
        }
    }
    .#{$prefix}_heading {
      margin-bottom: 35px;
      color: white;
      @include align_l;
    }
    &-text {
      margin: 0;
      padding-bottom: 50px;
      font-size: 19px;
      font-weight: 400;
      line-height: 32px;
      border-bottom: 1px solid white;
    }
    &-scheme {
      @include table(auto);
      max-width: 1130px;
      padding-top: 35px;
      padding-bottom: 35px;
        &__unit {
          position: relative;
          @include td(top);
          width: 50%;
          padding-top: 30px;
            &:first-of-type {
              padding-right: 50px;
            }
            &:last-of-type {
              padding-left: 50px;
                &::before {
                  content: "";
                  position: absolute;
                  top: 0;
                  left: 0;
                  display: block;
                  width: 1px;
                  height: 100%;
                  background: -moz-radial-gradient(center, ellipse cover, rgba(203,204,201,1) 0%, rgba(203,204,201,1) 33%, rgba(203,204,201,0) 73%, rgba(203,204,201,0) 99%);
                  background: -webkit-radial-gradient(center, ellipse cover, rgba(203,204,201,1) 0%,rgba(203,204,201,1) 33%,rgba(203,204,201,0) 73%,rgba(203,204,201,0) 99%);
                  background: radial-gradient(ellipse at center, rgba(203,204,201,1) 0%,rgba(203,204,201,1) 33%,rgba(203,204,201,0) 73%,rgba(203,204,201,0) 99%);
                  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cbccc9', endColorstr='#00cbccc9',GradientType=1 );
                }
            }
        }
        &__section {
          color: white;
          font-size: 15px;
          &:not(:last-of-type) {
            margin-bottom: 50px;
          }
        }
        &__heading {
          margin: 0 0 20px;
          color: white;
          font-size: 25px;
          font-weight: 400;
        }
        p {
          margin: 0;
          @include inherit;
          line-height: 25px;
        }
        ul {
          margin: 40px 0;
          list-style: none;
            li {
              position: relative;
              padding-left: 15px;
                &::before {
                  content: "";
                  position: absolute;
                  top: 12px;
                  left: 0;
                  display: block;
                  width: 10px;
                  height: 1px;
                  background: white;
                }
                &:not(:last-of-type) {
                  margin-bottom: 5px;
                }
            }
        }
    }

    @media all and (max-width: 769px) {
      padding-top: 75px;
      padding-bottom: 90px;
        &-scheme {
          display: block;
            &__unit {
              display: block;
              width: 100%;
              padding-top: 0;
                &:first-of-type {
                  padding-right: 0;
                }
                &:last-of-type {
                  margin-top: 65px;
                  padding-left: 0;
                  &::before {
                    display: none;
                  }
                }
            }
        }
    }
    @media all and (max-width: 481px) {
      padding-top: 55px;
      padding-bottom: 70px;
    }
}
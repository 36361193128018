##{$prefix}_footer {
  padding: 50px 0;
  background: $almostWhite;
    .#{$prefix}_logotype {
      margin-right: 18px;
    }
    .#{$prefix}_copyright {
      margin-right: 35px;
    }
    @media all and (max-width: 599px) {
      @include align_c;
        .#{$prefix}_logotype {
          display: block;
          @include auto;
          margin-bottom: 20px;
        }
        .#{$prefix}_copyright {
          display: block;
          @include auto;
          margin-bottom: 35px;
        }
    }
}
.#{$prefix}_footer {
  &-section {
    width: 100%;
    font-size: 0;
    &:not(:last-of-type) {
      margin-bottom: 40px;
    }
    @media all and (max-width: 599px) {
      &._tags {
        display: none;
      }
    }
  }
}
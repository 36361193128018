._arrows {
  position: relative;
    //slick
    .slick-arrow {
      position: absolute;
      top: 50%;
      width: 25px;
      height: 40px;
      margin-top: -20px;
      @include align_c;
      background: transparent;
      border: 0;
      cursor: pointer;
      opacity: .1;
      outline: 0;
      z-index: 2;
      transition: opacity .2s linear;
      .i {
        vertical-align: middle;
      }
      &:hover {
        opacity: 1;
      }
    }
    .slick-prev {
      left: 0;
    }
    .slick-next {
      right: 0;
    }
    .slick-disabled {
      opacity: .5;
      pointer-events: none;
    }
    //owl.carousel
    .owl-stage-outer {
      z-index: 1;
    }
    .owl-nav {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    .owl-prev,
    .owl-next {
      position: absolute;
      top: 50%;
      width: 25px;
      height: 40px;
      margin-top: -20px;
      cursor: pointer;
      z-index: 2;
        .i {
          vertical-align: middle;
        }
    }
    .owl-prev {
      left: 0;
    }
    .owl-next {
      right: 0;
    }
    .owl-arrow {
      display: block;
      width: 100%;
      height: 100%;
      background: transparent;
      border: 0;
      outline: 0;
      cursor: pointer;
    }
}
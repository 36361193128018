._dots {
  .slick-dots {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    font-size: 0;
    @include align_c;
      li {
        @include iblock(top);
        width: 10px;
        height: 10px;
          &:not(:last-of-type) {
            margin-right: 6px;
          }
          &.slick-active {
            button {
              background: $theme;
            }
          }
      }
      button {
        display: block;
        width: 100%;
        height: 100%;
        color: transparent;
        font-size: 0;
        background: white;
        border: 1px solid $theme;
        @include round;
        outline: 0;
        overflow: hidden;
        @include smooth(.1s);
      }
  }
}
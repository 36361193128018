/*
  Variables
*/

$prefix         :       'mm';
$size           :       16px;
$font           :       'Roboto', sans-serif;
$theme          :       #3790a3;
$color          :       #000000;
$red            :       #ff0000;
$blue           :       #147d93;
$darkBlue       :       #11697b;
$grey           :       #515151;
$lightBlue      :       #dcf5f7;
$link           :       #489bad;
$almostWhite    :       #f1f8f9;

$sprite: url(../../images/_sprite.png) no-repeat;

/*
  Size
*/

$_12px  : .75rem;
$_13px  : .81rem;
$_14px  : .88rem;
$_15px  : .937rem;
$_18px  : 1.125rem;
$_21px  : 1.312rem;
$_22px  : 1.35rem;
$_24px  : 1.5rem;
$_40px  : 2.5rem;
$_44px  : 2.75rem;
$_20px  : 1.25rem;
$_28px  : 1.75rem;
$_30px  : 1.88rem;
$_32px  : 2rem;
$_36px  : 2.25rem;
$_62px  : 3.875rem;
$_67px  : 4.187rem;

/*
  Mixins
*/

@mixin fix {
  content: '';
  display: block;
  width: 100%;
  height: 0;
  visibility: hidden;
  clear: both;
}
@mixin smooth($var) {
  transition: all $var linear;
}
@mixin table($hi) {
  display: table;
  width: 100%;
  height: $hi;
  table-layout: fixed;
}
@mixin td($align){
  display: table-cell;
  vertical-align: $align;
}

@mixin iblock($align) {
  display: inline-block;
  vertical-align: $align;
}

@mixin noselect {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
@mixin align_l {
  text-align: left;
}
@mixin align_c {
  text-align: center;
}
@mixin align_r {
  text-align: right;
}
@mixin align_j {
  text-align: justify;
}
@mixin bold {
  font-weight: bold;
}
@mixin upp {
  text-transform: uppercase;
}
@mixin image($width, $m_width) {
  width: $width;
  max-width: $m_width;
}
@mixin link($hi) {
  display: block;
  height: $hi;
  text-decoration: none;
}
@mixin auto {
  margin-left: auto;
  margin-right: auto;
}

@mixin inherit {
  color: inherit;
  font-size: inherit;
}

@mixin round {
  border-radius: 50%;
}

@mixin input($hi, $padding) {
  display: block;
  width: 100%;
  height: $hi;
  padding: $padding;
  font-size: 15px;
  background: white;
  border: 1px solid #dadada;
  box-shadow: inset 0 5px 7px rgba(black, 0.07);
  border-radius: 6px;
  outline: 0;
  resize: none;
}
@mixin button($width, $hi, $padding, $bg) {
  @include iblock(top);
  width: $width;
  height: $hi;
  padding: $padding;
  font-size: 1rem;
  @include align_c;
  text-decoration: none;
  background: $bg;
  border: 0;
  border-radius: 0;
  outline: 0;
}
@mixin full {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
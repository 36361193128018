.#{$prefix}_tags {
  color: $theme;
  margin-left: -17px;
  margin-right: -17px;
  font-size: 0;
    &__item {
      @include iblock(top);
      margin-bottom: 10px;
      padding: 0 17px;
      font-size: 13px;
        &:not(:last-of-type) {
          border-right: 1px solid #d4d9da;
        }
    }
    &__link {
      @include inherit;
      text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
    }
}